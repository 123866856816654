$(document).ready(function () {
  console.log('run dev');

  $('.history-header .btn-toggle').on('click', function () {
    $(this).parent().siblings('.history-body').slideToggle(300);
    $(this).parent().toggleClass('active');
  });

  var rowNumber = 1;
  $('#btnAddMoreUser').on('click', function () {
    console.log('click');
    rowNumber++;
    $(generateUserFormRow(rowNumber)).insertBefore($(this));
  });
  $('.add-user-form').on('click', '.btn-removerow', function () {
    console.log('remove');
    $(this).parent().remove();
  });

  function generateUserFormRow(order) {
    return (
      `<div class="form-row-more" id="formRow` +
      order +
      `">
    <div class="form-group form-floating">
      <input class="form-control" id="user` +
      order +
      `" type="email" placeholder="Enter user email">
      <label for="user` +
      order +
      `">User #` +
      order +
      `</label>
    </div><a class="fa-light fa-trash-can btn-removerow"></a>
  </div>`
    );
  }

  if ($(window).innerWidth() >= 1200) {
    $('.menu-product').on('mouseenter', function () {
      $('.icon-menu').addClass('active');
      $('.menu').fadeIn(300);
      $('.menu-overlay').fadeIn(300);
    });
    $('.menu-product').on('mouseleave', function () {
      $('.icon-menu').removeClass('active');
      $('.menu').fadeOut(300);
      $('.menu-overlay').fadeOut(300);
    });
  } else {
    $('.btn-menu').on('click', function () {
      $('.icon-menu').toggleClass('active');
      $('.menu').fadeToggle(300);
    });
    $('.btn-openchild').on('click', function () {
      $(this).siblings('.child-menu').addClass('open');
    });
    $('.btn-closechild').on('click', function () {
      $(this).parent('.child-menu').removeClass('open');
    });
    $('.btn-opensub').on('click', function () {
      $(this).siblings('.sub-menu').addClass('open');
    });
    $('.btn-closesub').on('click', function () {
      $(this).parent('.sub-menu').removeClass('open');
    });
  }

  $('.btn-opencate').on('click', function () {
    $('.category-container').fadeIn(300);
  });
  $('.btn-closecate').on('click', function () {
    $('.category-container').fadeOut(300);
  });

  $('.btn-openfilter').on('click', function () {
    $('.sidebar-filter').fadeIn(300);
  });
  $('.btn-closefilter').on('click', function () {
    $('.sidebar-filter').fadeOut(300);
  });

  $('.btn-viewmore').on('click', function () {
    $('.product-technical .content').css('max-height', 'unset');
    $(this).remove();
  });

  $('.btn-showaction').on('click', function () {
    $(this).siblings('.table-action').fadeToggle(300);
  });
  $('.table-action').on('click','.btn-link',function(){
    $(this).parent().fadeOut(300);
  })

  var lastOffset = 0;
  $(window).on('scroll', function () {
    if ($(window).innerWidth() >= 1200) {
      var currentOffset = $(this).scrollTop();
      if (currentOffset > 156) {
        $('.header').addClass('floating');
      } else {
        $('.header').removeClass('floating');
      }
      lastOffset = currentOffset;
    }
  });

  if ($(window).innerWidth() < 992) {
    $('.search-box').insertBefore($('main').children().first());
  }
});
